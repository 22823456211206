import { patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { exhaustMap, switchMap, take, tap } from "rxjs";
import { computed, inject } from "@angular/core";
import { ApiService } from "../../api/http-services/api.service";
import { PartnerData, SubscriptionData, TariffType } from "../widgets/widgets.types";
import { AmoData, ClientData, SubscriptionTimeInfo } from "./environment.types";
import { AccountDataRequest, SubscriptionDataRequest, } from "../../api/http-services/api.types";
import { toObservable } from "@angular/core/rxjs-interop";
import { filter } from "rxjs/operators";

type EnvironmentState = {
    client_id: string,
    amo: AmoData,
    lang: string,
    subscription: SubscriptionData,
    partner: PartnerData | false,
    client: ClientData,
    widgetName: string
}

const initialState: EnvironmentState = {
    client_id: "",
    amo: {
        domain: "",
        account_id: 0
    },
    lang: "ru",
    subscription: {
        demo: true,
        expiration: 0,
        name: 'junior'
    },
    partner: false,
    client: {
        amo_id: null,
        name: null,
        phone: null,
        email: null,
        company: null,
        job: null,
        site: null,
        city: null,
        comment: null,
        partner_id: null,
        invite_accepted_at: undefined,
        selecting_partner: null,
        stage: null
    },
    widgetName: 'robocodetriggers'
}

export const EnvironmentStore = signalStore(
    { providedIn: 'root', protectedState: false},
    withState(initialState),
    withMethods((store, apiService: ApiService = inject(ApiService)) => ({
        loadClientData: rxMethod<void>(
            exhaustMap(() => {
                return apiService.getAccountData().pipe(
                    tap({
                        next: (accountData: AccountDataRequest) => {
                            patchState(store, {
                                client_id: accountData.client_id,
                                amo: accountData.amo
                            });
                        },
                        error: console.error,
                    })
                )
            })
        ),
        loadSubscriptionData: rxMethod<void>(
            exhaustMap(() => {
                return toObservable(store.amo).pipe(
                    filter((amo_data: AmoData) => !!amo_data.domain),
                    take(1),
                    switchMap((amo_data: AmoData) => {
                        return apiService.getSubscriptionData(amo_data.domain).pipe(
                            tap({
                                next: (subscriptionData: SubscriptionDataRequest) => {
                                    patchState(store, { client: subscriptionData.client })
                                },
                                error: console.error,
                            })
                        );
                    })
                )
            })
        )
    })),
    withComputed((store) => ({
        isDemoEnabled: computed(() => {
            return store.subscription.name() === 'demo' && new Date().getTime() < new Date(store.subscription.expiration()*1000).getTime();
        }),
        isJuniorEnabled: computed((): boolean => {
            let tariffType: TariffType = store.subscription.name();
            return tariffType === 'junior' || tariffType === 'middle' || tariffType === 'senior';
        }),
        isMiddleEnabled: computed((): boolean => {
            return store.subscription.name() === 'middle' || store.subscription.name() === 'senior';
        }),
        isSeniorEnabled: computed((): boolean => {
            return store.subscription.name() === 'senior'
        }),
        subscriptionDataInfo: computed((): SubscriptionTimeInfo  => {
            return {
                expires: new Date(store.subscription.expiration()*1000).getTime(),
                tariff: store.subscription.name()
            }
        }),
    }))
)
