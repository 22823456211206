import { inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AccountDataRequest, AddClientDataRequest, HelpClientDataRequest, ProlongPartnerDataRequest, SelectPartnerDataRequest, SubscriptionDataRequest } from "./api.types";
import { getSubscriptionData } from "./functions/getSubscriptionData";
import { getWidgetsData } from "./functions/getWidgetsData";
import { WidgetsData } from "../../stores/widgets/widgets.types";
import { postAddClient } from "./functions/postAddClient";
import { ClientData } from "../../stores/environment/environment.types";
import { postTariffForm } from "./functions/postTariffForm";
import { ApiConfiguration } from "./api-configuration";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private httpClient: HttpClient = inject(HttpClient);
    private apiConfiguration: ApiConfiguration = inject(ApiConfiguration);
    private readonly apiRobocodeUrl: string = this.apiConfiguration.apiRobocodeUrl;
    private readonly apiIntrovertUrl: string = this.apiConfiguration.apiIntrovertUrl;

    public getAccountData(): Observable<AccountDataRequest> {
        return this.httpClient.get<any>(`${this.apiRobocodeUrl}/api/account`);
    }

    public getWidgetData(domain: string, lang: string): Observable<WidgetsData> {
        return getWidgetsData(this.httpClient, this.apiIntrovertUrl, domain, lang);
    }

    public getSubscriptionData(domain: string): Observable<SubscriptionDataRequest> {
        return getSubscriptionData(this.httpClient, this.apiIntrovertUrl, domain);
    }

    public postAddClient(data: AddClientDataRequest): Observable<ClientData> {
        return postAddClient(this.httpClient, this.apiIntrovertUrl, data);
    }

    public postTariffForm(data: HelpClientDataRequest | SelectPartnerDataRequest | ProlongPartnerDataRequest): Observable<ClientData> {
        return postTariffForm(this.httpClient, this.apiIntrovertUrl, data);
    }
}
